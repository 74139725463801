<template>
  <div>
    <v-row class="mx-4">
      <v-col cols="12">
        <!-- "Search by" radio group in row orientation for normal screens -->
        <v-radio-group
          v-model="radios"
          mandatory
          label="Search by"
          prepend-icon="mdi-magnify"
          row
          class="d-none d-sm-flex d-md-flex d-lg-flex"
          @change="resetForm"
        >
          <v-radio label="Address" class="mx-10" :value="true"></v-radio>
          <v-radio label="Lot and Plan" :value="false"></v-radio>
        </v-radio-group>
        <!-- "Search by" radio group in column orientation for very small screens -->
        <v-radio-group
          v-model="radios"
          mandatory
          label="Search by"
          prepend-icon="mdi-magnify"
          column
          class="d-flex d-sm-none d-md-none d-lg-none"
          @change="resetForm"
        >
          <v-radio label="Address" :value="true"></v-radio>
          <v-radio label="Lot and Plan" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col>
        <v-autocomplete
          v-if="radios"
          v-model="selectedAddress"
          :loading="loading"
          :items="items"
          :search-input.sync="search"
          cache-items
          flat
          hide-no-data
          hint="e.g. 324 Queen Street, Brisbane City"
          prepend-icon="mdi-home-city-outline"
          label="Please enter an address to start searching"
          class="autocomplete"
        />
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              v-if="!radios"
              v-model="inputLot"
              label="Lot"
              placeholder="Lot"
              hint="e.g. 101"
              prepend-icon="mdi-home-edit"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <v-text-field
              v-if="!radios"
              v-model="inputPlan"
              label="Plan"
              placeholder="Plan"
              hint="e.g. SP102966"
              prepend-icon="mdi-map-check-outline"
              append-icon="mdi-magnify"
              @keydown.enter="queryLotPlan(inputLot, inputPlan)"
              @click:append="queryLotPlan(inputLot, inputPlan)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="#1967a9"
          size="20"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div v-if="!!selectedAddress" class="pa-6">
          <address-card
            color="info"
            icon="mdi-home-city-outline"
            :title="selectedAddress"
            @display-parcel="displayParcel"
          />
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="hasNoMatchingAddress" color="error">
      No matching addresses found. Please enter a new address.
    </v-snackbar>
  </div>
</template>

<script>
import { debounce } from "lodash";
import MapService from "@/services/MapService";

export default {
  components: {
    AddressCard: () => import("@/components/address/AddressCard"),
  },
  data: () => ({
    loading: false,
    radios: true,
    items: [],
    search: null,
    inputLot: "",
    inputPlan: "",
    selectedAddress: null,
    hasNoMatchingAddress: false,
    hasInflightQuery: false,
    abortController: null,
  }),
  watch: {
    search(val) {
      val &&
        this.radios && // only search if address search?
        val !== this.selectedAddress &&
        val.length >= 5 &&
        this.querySuggestions(val);
    },
    selectedAddress: debounce(async function (newAddress) {
      this.hasNoMatchingAddress = false;

      // Bug was found when there's an existing selected address and "Search by Lot Plan" was selected (which clears `this.selectedAddress` back to null).
      // This check was added so we only process fetching lot plans if `this.selectedAddress` is not null.
      if (newAddress != null) {
        this.loading = true;
        document.activeElement.blur();
        const lotplans = await this.$store.dispatch(
          "fetchAddressLotPlans",
          newAddress
        );
        if (lotplans.length === 1) {
          await this.$store.dispatch("setCurrentParcel", lotplans[0]);
          this.displayParcel();
        } else {
          this.clearParcel();
        }
        this.loading = false;
      }
      this.updateSelectedAddress();
    }, 1000),
  },
  created() {
    if (this.$route.query && this.$route.query.selectedAddress) {
      // alert("queryString in searchAddress")
      this.selectedAddress = String(this.$route.query.selectedAddress);
    }
  },
  methods: {
    resetForm() {
      this.selectedAddress = null;
      this.inputLot = "";
      this.inputPlan = "";
      this.items = [];
      this.clearParcel();
    },

    displayParcel() {
      this.$emit("display-parcel");
    },

    clearParcel() {
      this.$emit("clear-parcel");
    },

    updateSelectedAddress() {
      this.$emit("update-selected-address", this.selectedAddress);
    },

    querySuggestions: debounce(async function (inputAddress) {
      this.loading = true;

      if (this.hasInflightQuery) {
        this.abortController.abort();
        this.abortController = null;
        this.hasInflightQuery = false;
      }

      this.abortController = new AbortController();
      this.hasInflightQuery = true;

      try {
        const result = await MapService.fetchSuggestions(inputAddress, this.abortController.signal);
        this.items = result.items;
        this.hasNoMatchingAddress = result.hasNoMatchingAddress;
      } catch(error) {
          if (error.name === 'AbortError') {
            // eslint-disable-next-line no-console
            console.log('Fetch aborted');
          } else {
            // eslint-disable-next-line no-console
            console.error('Unexpected Error Encountered: ', error);
          }
      }
      this.loading = false;
      this.hasInflightQuery = false;
    }, 1000),

    queryLotPlan: debounce(async function (inputLot, inputPlan) {
      this.loading = true;

      // Convert all 0 lot numbers to 9999
      // to match data between geocoding service and cadastral parcel feature layer service
      if (inputLot === "0") {
        inputLot = "9999";
      }

      const result = await MapService.parseLotPlan(inputLot, inputPlan);

      this.hasNoMatchingAddress = result.hasNoMatchingAddress;
      if (!this.hasNoMatchingAddress) {
        this.selectedAddress = result.addressDetails[0].MetaData[1].Value;
      }

      this.loading = false;
    }, 1000),
  },
};
</script>

<style>
.autocomplete input {
  text-transform: uppercase;
}
</style>
